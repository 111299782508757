import React, { useState, useRef, useEffect } from "react";
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm  } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import emailjs from '@emailjs/browser';

import swal from 'sweetalert'

const HistoriaClinicaForm = () => {

    const form = useRef()

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();


    const [historiaClinica, setHistoriaClinica] = useState({
        date: new Date().toLocaleDateString('es-AR'),
        nombre: "",
        apellido: "",
        dni: "",
        fechadenacimiento: "",
        edad: "",
        nacionalidad: "",
        paisresidencia: "",
        direccion: "",
        piso: "",
        departamento: "",
        localidad: "",
        codigopostal: "",
        provincia: "",
        ocupacion: "",
        coberturamedica: "",
        plan: "",
        nroafiliado: "",
        email: "",
        telefono: "",

        motivoconsulta: "",
        altura: "",
        peso: "",
        antecedentesquirurgicos: "",
        antecedentescuales: "",
        cirugiasesteticas: "",
        cirugiascuales: "",
        anocirugia: "",
        medicocirugia: "",
        conformecirugia: "",
        antecedentespatologicos: "",
        patologiascuales: "",
        enfermedadactual: "",
        tomamedicacion: "",
        medicacioncual: "",
        tomaanticonceptivos: "",
        antecedentetrombofiliacoagulacion: "",
        alergia: "",
        alergiacual: "",
        vacunacovid: "",
        vacunacovidcuantas: "",
        vacunacovidcuales: "",
        covidinternacion: "",
        covidinternaciondias: "",
        covidsecuela: "",
        covidquesecuela: "",
        embarazos: "",
        embarazoscuantos: "",
        cesareaopartonatural: "",
        abortos: "",
        hijosmas: "",
        varices: "",

        alimentacionequilibrada: "",
        alimentaciontipo: "",
        actividadfisica: "",
        actividadfisicatipo: "",
        actividadfisicafrecuencia: "",
        fuma: "",
        desdecuandofuma: "",
        quefuma: "",
        fumacuantos: "",
        fumo: "",
        fumocuantos: "",
        cuandodejofumar: "",
        alcohol: "",
        alcoholcuando: "",
        alcoholcuanto: "",
        cambiohabitopostintervencion: "",
        comoconocioluque: "",
        valido: "",
    });

    useEffect(() => {
        Object.keys(historiaClinica).forEach((key) => {
            setValue(key, historiaClinica[key]);
        });
    }, [historiaClinica, setValue]);

    const sendEmail = (e) => {
    
    emailjs.sendForm('landing', 'mail-historia-clinica', form.current, '0MTk0AxmZ5JHk97LX')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        setHistoriaClinica((prevState) => {
        if (prevState.quefuma.includes(value)) {
        return {
            ...prevState,
            quefuma: prevState.quefuma.filter((item) => item !== value),
        };
        } else {
        return {
            ...prevState,
            quefuma: [...prevState.quefuma, value],
        };
        }
    });
    };

    const navigate = useNavigate();

    const createHistoriaClinica = () => {
        
        setDoc(doc(db, "historiaClinica", historiaClinica.dni), historiaClinica).then(() => {
            swal(
                "GRACIAS POR TU CONTACTO", 
                `Recibimos tu historia clínica`, 
                "success");
            sendEmail()
            navigate('/')
        }).catch(error => {
            console.log(error)
            swal(
                "¡Ups!", 
                `Algo falló`, 
                "error");
        })
    }
    
    return(
        <section>
            <div className="historia-clinica">
                    
                    <form ref={form} onSubmit={handleSubmit(createHistoriaClinica)}>
                        <div className="container-form-historia-clinica">
                            <h3>TUS DATOS</h3>
                            <div className="box-historia-clinica">
                                <label>NOMBRE</label>
                                <input 
                                    type="text" 
                                    name="nombre" 
                                    value={historiaClinica.nombre}
                                    {...register("nombre", { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, nombre: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="nombre"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>APELLIDO</label>
                                <input 
                                    type="text" 
                                    name="apellido" 
                                    value={historiaClinica.apellido}
                                    {...register('apellido', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, apellido: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="apellido"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>DNI / ID</label>
                                <input 
                                    type="number" 
                                    name="dni" 
                                    pattern="[0-9]+" 
                                    value={historiaClinica.dni}
                                    {...register('dni', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, dni: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="dni"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>FECHA DE NACIMIENTO</label>
                                <input 
                                    type="date" 
                                    name="fechadenacimiento" 
                                    value={historiaClinica.fechadenacimiento}
                                    {...register('fechadenacimiento', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, fechadenacimiento: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="fechadenacimiento"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>EDAD</label>
                                <input 
                                    type="number" 
                                    name="edad" 
                                    pattern="[0-9]+" 
                                    value={historiaClinica.edad}
                                    {...register('edad', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, edad: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="edad"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>NACIONALIDAD</label>
                                <input 
                                    type="text" 
                                    name="nacionalidad" 
                                    value={historiaClinica.nacionalidad}
                                    {...register('nacionalidad', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, nacionalidad: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="nacionalidad"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>PAÍS DE RESIDENCIA</label>
                                <input 
                                    type="text" 
                                    name="paisresidencia" 
                                    value={historiaClinica.paisresidencia}
                                    {...register('paisresidencia', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, paisresidencia: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="paisresidencia"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                            
                            <div className="box-historia-clinica">
                                <label>DIRECCIÓN (CALLE Y NRO)</label>
                                <input 
                                    type="text" 
                                    name="direccion" 
                                    value={historiaClinica.direccion}
                                    {...register('direccion', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, direccion: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="direccion"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>PISO</label>
                                <input 
                                    type="text" 
                                    name="piso" 
                                    value={historiaClinica.piso}
                                    {...register('piso', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, piso: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="piso"
                                    render={({ message }) => <p>{message}</p>}
                                />
                                
                                <label>DEPARTAMENTO</label>
                                <input 
                                    type="text" 
                                    name="departamento" 
                                    value={historiaClinica.departamento}
                                    {...register('departamento', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, departamento: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="departamento"
                                    render={({ message }) => <p>{message}</p>}
                                />
                                
                                <label>LOCALIDAD</label>
                                <input 
                                    type="text" 
                                    name="localidad" 
                                    value={historiaClinica.localidad}
                                    {...register('localidad', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, localidad: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="localidad"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>CÓDIGO POSTAL</label>
                                <input 
                                    type="text" 
                                    name="codigopostal" 
                                    value={historiaClinica.codigopostal}
                                    {...register('codigopostal', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, codigopostal: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="codigopostal"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>PROVINCIA</label>
                                <input 
                                    type="text" 
                                    name="provincia" 
                                    value={historiaClinica.provincia}
                                    {...register('provincia', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, provincia: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="provincia"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>OCUPACIÓN</label>
                                <input 
                                    type="text" 
                                    name="ocupacion" 
                                    value={historiaClinica.ocupacion}
                                    {...register('ocupacion', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, ocupacion: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="ocupacion"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                            
                            <div className="box-historia-clinica">

                                <label>COBERTURA MÉDICA</label>
                                <div className="radiobutton">
                                    <label>PREPAGA</label>
                                    <input 
                                        type="radio" 
                                        name="coberturamedica" 
                                        value="prepaga"                                        
                                        {...register('coberturamedica', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, coberturamedica: e.target.value})}
                                    />
                                    <label>PARTICULAR</label>
                                    <input 
                                        type="radio" 
                                        name="coberturamedica" 
                                        value="particular"
                                        {...register('coberturamedica', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, coberturamedica: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="coberturamedica"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.coberturamedica === "prepaga"
                                    ? <div>
                                        <label>COBERTURA Y PLAN</label>
                                        <input 
                                            type="text" 
                                            name="plan" 
                                            value={historiaClinica.plan}
                                            {...register('plan', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, plan: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="plan"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                {
                                    historiaClinica.coberturamedica === "prepaga"
                                    ? <div>
                                        <label>NÚMERO DE AFILIADO</label>
                                        <input 
                                            type="text" 
                                            name="nroafiliado" 
                                            value={historiaClinica.nroafiliado}
                                            {...register('nroafiliado', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, nroafiliado: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="nroafiliado"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                <label>EMAIL</label>
                                <input 
                                    type="email" 
                                    name="email" 
                                    value={historiaClinica.email}
                                    {...register('email', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, email: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>TELÉFONO</label>
                                <input 
                                    type="number" 
                                    name="telefono" 
                                    pattern="[0-9]+" 
                                    value={historiaClinica.telefono}
                                    {...register('telefono', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, telefono: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="telefono"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </div>
                        </div>

                        <div className="container-form-historia-clinica bkg-gris">
                            <h3>TU HISTORIA CLÍNICA</h3>
                            <div className="box-historia-clinica">
                                <label>MOTIVO DE LA CONSULTA</label>
                                <textarea 
                                    type="text" 
                                    name="motivoconsulta" 
                                    rows="6" 
                                    value={historiaClinica.motivoconsulta}
                                    {...register('motivoconsulta', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, motivoconsulta: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="motivoconsulta"
                                    render={({ message }) => <p>{message}</p>}
                                />


                                <label>PESO</label>
                                <input 
                                    type="number" 
                                    name="peso" 
                                    pattern="[0-9]+" 
                                    value={historiaClinica.peso}
                                    {...register('peso', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, peso: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="peso"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>ALTURA</label>
                                <input 
                                    type="number" 
                                    name="altura" 
                                    pattern="[0-9]+" 
                                    value={historiaClinica.altura}
                                    {...register('altura', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, altura: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="altura"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>ANTECEDENTES QUIRÚRGICOS</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="antecedentesquirurgicos" 
                                        value="si"
                                        {...register('antecedentesquirurgicos', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, antecedentesquirurgicos: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="antecedentesquirurgicos" 
                                        value="no"
                                        {...register('antecedentesquirurgicos', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, antecedentesquirurgicos: e.target.value})}
                                    />
                                    <ErrorMessage
                                    errors={errors}
                                    name="antecedentesquirurgicos"
                                    render={({ message }) => <p>{message}</p>}
                                />
                                </div>

                                {
                                    historiaClinica.antecedentesquirurgicos === "si"
                                    ? <div>
                                        <label>¿CUALES?</label>
                                        <textarea 
                                            type="text" 
                                            name="antecedentescuales" 
                                            rows="6" 
                                            placeholder="Contanos tus antecedentes quirúrgicos"
                                            value={historiaClinica.antecedentescuales}
                                            {...register('antecedentescuales', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, antecedentescuales: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="antecedentescuales"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }

                                <label>CIRUGÍAS ESTÉTICAS</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="cirugiasesteticas" 
                                        value="si"
                                        
                                        {...register('cirugiasesteticas', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, cirugiasesteticas: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="cirugiasesteticas" 
                                        value="no"
                                        {...register('cirugiasesteticas', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, cirugiasesteticas: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="cirugiasesteticas"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.cirugiasesteticas === "si"
                                    ? <div>
                                        <label>¿CUALES?</label>
                                        <textarea 
                                            type="text" 
                                            name="cirugiascuales" 
                                            rows="6" 
                                            placeholder="Contanos tus cirugías estéticas previas"
                                            value={historiaClinica.cirugiascuales}
                                            {...register('cirugiascuales', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, cirugiascuales: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cirugiascuales"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }

                                {
                                    historiaClinica.cirugiasesteticas === "si"
                                    ? <div>
                                        <label>¿AÑO DE LA CIRUGÍA ESTÉTICA?</label>
                                        <input 
                                            type="number" 
                                            name="anocirugia" 
                                            pattern="[0-9]+" 
                                            value={historiaClinica.anocirugia}
                                            {...register('anocirugia', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, anocirugia: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="anocirugia"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }

                                {
                                    historiaClinica.cirugiasesteticas === "si"
                                    ? <div>
                                        <label>¿QUÉ MÉDICO REALIZÓ LA CIRUGÍA?</label>
                                        <input 
                                            type="text" 
                                            name="medicocirugia" 
                                            value={historiaClinica.medicocirugia}
                                            {...register('medicocirugia', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, medicocirugia: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="medicocirugia"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }

                                {
                                    historiaClinica.cirugiasesteticas === "si"
                                    ? <div>
                                        <label>¿QUEDÓ CONFORME?</label>
                                        <input 
                                            type="text" 
                                            name="conformecirugia" 
                                            value={historiaClinica.conformecirugia}
                                            {...register('conformecirugia', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, conformecirugia: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="conformecirugia"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }

                                
                            </div>
                            
                            <div className="box-historia-clinica">
                                <label>ANTECEDENTES PATOLÓGICOS</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="antecedentespatologicos" 
                                        value="si"
                                        
                                        {...register('antecedentespatologicos', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, antecedentespatologicos: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="antecedentespatologicos" 
                                        value="no"
                                        {...register('antecedentespatologicos', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, antecedentespatologicos: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="antecedentespatologicos"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.antecedentespatologicos === "si"
                                    ? <div>
                                        <label>¿CUALES?</label>
                                        <textarea 
                                            type="text" 
                                            name="patologiascuales" 
                                            rows="6" 
                                            placeholder="Contanos tus patologías previas"
                                            value={historiaClinica.patologiascuales}
                                            {...register('patologiascuales', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, patologiascuales: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="patologiascuales"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }

                                <label>¿POSEE ALGUNA ENFERMEDAD EN LA ACTUALIDAD?</label>
                                <input 
                                    type="text" 
                                    name="enfermedadactual" 
                                    value={historiaClinica.enfermedadactual}
                                    {...register('enfermedadactual', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, enfermedadactual: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="enfermedadactual"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>¿TOMA ALGUNA MEDICACIÓN EN LA ACTUALIDAD?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="tomamedicacion" 
                                        value="si"
                                        
                                        {...register('tomamedicacion', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, tomamedicacion: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="tomamedicacion" 
                                        value="no"
                                        {...register('tomamedicacion')}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, tomamedicacion: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="tomamedicacion"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.tomamedicacion === "si"
                                    ? <div>
                                        <label>¿QUÉ MEDICACIÓN?</label>
                                        <input 
                                            type="text" 
                                            name="medicacioncual" 
                                            value={historiaClinica.medicacioncual}
                                            {...register('medicacioncual', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, medicacioncual: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="medicacioncual"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }

                                <label>¿TOMA ANTICONCEPTIVOS?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="tomaanticonceptivos" 
                                        value="si"
                                        
                                        {...register('tomaanticonceptivos', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, tomaanticonceptivos: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="tomaanticonceptivos" 
                                        value="no"
                                        {...register('tomaanticonceptivos')}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, tomaanticonceptivos: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="tomaanticonceptivos"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>                 

                                <label>¿TIENE ANTECEDENTES DE TROMBOFILIA O ALTERACIONES EN LA COAGULACIÓN?</label>
                                <input 
                                    type="text" 
                                    name="antecedentetrombofiliacoagulacion" 
                                    value={historiaClinica.antecedentetrombofiliacoagulacion}
                                    {...register('antecedentetrombofiliacoagulacion', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, antecedentetrombofiliacoagulacion: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="antecedentetrombofiliacoagulacion"
                                    render={({ message }) => <p>{message}</p>}
                                />

                                <label>¿TIENE ALGUNA ALERGIA?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="alergia" 
                                        value="si"
                                        
                                        {...register('alergia', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, alergia: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="alergia" 
                                        value="no"
                                        {...register('alergia', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, alergia: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="alergia"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.alergia === "si"
                                    ? <div>
                                        <label>¿CUÁL?</label>
                                        <input 
                                            type="text" 
                                            name="alergiacual" 
                                            value={historiaClinica.alergiacual}
                                            {...register('alergiacual', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, alergiacual: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="alergiacual"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }  

                                </div>

                                <div className="box-historia-clinica">

                                <label>¿SE VACUNÓ CONTRA EL COVID-19?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="vacunacovid" 
                                        value="si"
                                        
                                        {...register('vacunacovid', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, vacunacovid: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="vacunacovid" 
                                        value="no"
                                        {...register('vacunacovid', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, vacunacovid: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="vacunacovid"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.vacunacovid === "si"
                                    ? <div>
                                        <label>¿CUÁNTAS VACUNAS?</label>
                                        <input 
                                            type="number" 
                                            name="vacunacovidcuantas" 
                                            pattern="[0-9]+" 
                                            value={historiaClinica.vacunacovidcuantas}
                                            {...register('vacunacovidcuantas', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, vacunacovidcuantas: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="vacunacovidcuantas"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                {
                                    historiaClinica.vacunacovid === "si"
                                    ? <div>
                                        <label>¿QUÉ VACUNAS?</label>
                                        <input 
                                            type="text" 
                                            name="vacunacovidcuales" 
                                            value={historiaClinica.vacunacovidcuales}
                                            {...register('vacunacovidcuales', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, vacunacovidcuales: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="vacunacovidcuales"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                <label>¿TUVO INTERNACIÓN RELACIONADA AL COVID-19?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="covidinternacion" 
                                        value="si"
                                        
                                        {...register('covidinternacion', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, covidinternacion: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="covidinternacion" 
                                        value="no"
                                        {...register('covidinternacion', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, covidinternacion: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="covidinternacion"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.covidinternacion === "si"
                                    ? <div>
                                        <label>¿CUÁNTOS DÍAS DE INTERNACIÓN?</label>
                                        <input 
                                            type="text" 
                                            name="covidinternaciondias" 
                                            value={historiaClinica.covidinternaciondias}
                                            {...register('covidinternaciondias', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, covidinternaciondias: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="covidinternaciondias"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                <label>¿EL COVID-19 LE DEJÓ ALGUNA SECUELA?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="covidsecuela" 
                                        value="si"
                                        
                                        {...register('covidsecuela', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, covidsecuela: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="covidsecuela" 
                                        value="no"
                                        {...register('covidsecuela', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, covidsecuela: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="covidsecuela"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.covidsecuela === "si"
                                    ? <div>
                                        <label>¿QUÉ SECUELA/S?</label>
                                        <input 
                                            type="text" 
                                            name="covidquesecuela" 
                                            value={historiaClinica.covidquesecuela}
                                            {...register('covidquesecuela', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, covidquesecuela: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="covidquesecuela"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                            </div>

                            <div className="box-historia-clinica">

                            <label>¿EMBARAZOS?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="embarazos" 
                                        value="si"
                                        
                                        {...register('embarazos', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, embarazos: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="embarazos" 
                                        value="no"
                                        {...register('embarazos', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, embarazos: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="embarazos"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.embarazos === "si"
                                    ? <div>
                                        <label>¿CUÁNTOS EMBARAZOS?</label>
                                        <input 
                                            type="number" 
                                            name="embarazoscuantos" 
                                            pattern="[0-9]+" 
                                            value={historiaClinica.embarazoscuantos}
                                            {...register('embarazoscuantos', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, embarazoscuantos: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="embarazoscuantos"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                {
                                    historiaClinica.embarazos === "si"
                                    ? <div>
                                        <label>¿CESÁREA O PARTO NATURAL?</label>
                                        <input 
                                            type="text" 
                                            name="cesareaopartonatural" 
                                            value={historiaClinica.cesareaopartonatural}
                                            {...register('cesareaopartonatural', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, cesareaopartonatural: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cesareaopartonatural"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                {
                                    historiaClinica.embarazos === "si"
                                    ? <div>
                                        <label>¿TUVO ABORTOS?</label>
                                        <input 
                                            type="text" 
                                            name="abortos" 
                                            value={historiaClinica.abortos}
                                            {...register('abortos', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, abortos: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="abortos"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                {
                                    historiaClinica.embarazos === "si"
                                    ? <div>
                                        <label>¿TIENE PENSADO TENER MÁS HIJOS?</label>
                                        <input 
                                            type="text" 
                                            name="hijosmas" 
                                            value={historiaClinica.hijosmas}
                                            {...register('hijosmas', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, hijosmas: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="hijosmas"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                <label>¿VÁRICES?</label>
                                <input 
                                    type="text" 
                                    name="varices" 
                                    value={historiaClinica.varices}
                                    {...register('varices', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, varices: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="varices"
                                    render={({ message }) => <p>{message}</p>}
                                />

                            </div>
                        </div>

                        <div className="container-form-historia-clinica">
                            <h3>HÁBITOS</h3>
                            <div className="box-historia-clinica">
                            
                                <label>¿LLEVA UNA ALIMENTACIÓN EQUILIBRADA?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="alimentacionequilibrada" 
                                        value="si"
                                        {...register('alimentacionequilibrada', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, alimentacionequilibrada: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="alimentacionequilibrada" 
                                        value="no"
                                        {...register('alimentacionequilibrada', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, alimentacionequilibrada: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="alimentacionequilibrada"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.alimentacionequilibrada === "si"
                                    ? <div>
                                        <label>¿DE QUÉ TIPO?</label>
                                        <input 
                                            type="text" 
                                            name="alimentaciontipo" 
                                            value={historiaClinica.alimentaciontipo}
                                            {...register('alimentaciontipo'/*, { required: "Este campo es obligatorio" }*/)}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, alimentaciontipo: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="alimentaciontipo"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                <label>¿REALIZA ACTIVIDAD FÍSICA?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="actividadfisica" 
                                        value="si"
                                        
                                        {...register('actividadfisica', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, actividadfisica: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="actividadfisica" 
                                        value="no"
                                        {...register('actividadfisica', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, actividadfisica: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="actividadfisica"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.actividadfisica === "si"
                                    ? <div>
                                        <label>¿QUÉ TIPO DE ACTIVIDAD?</label>
                                        <input 
                                            type="text" 
                                            name="actividadfisicatipo" 
                                            value={historiaClinica.actividadfisicatipo}
                                            {...register('actividadfisicatipo'/*, { required: "Este campo es obligatorio" }*/)}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, actividadfisicatipo: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="actividadfisicatipo"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                {
                                    historiaClinica.actividadfisica === "si"
                                    ? <div>
                                        <label>¿CON QUÉ FRECUENCIA?</label>
                                        <input 
                                            type="text" 
                                            name="actividadfisicafrecuencia" 
                                            value={historiaClinica.actividadfisicafrecuencia}
                                            {...register('actividadfisicafrecuencia'/*, { required: "Este campo es obligatorio" }*/)}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, actividadfisicafrecuencia: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="actividadfisicafrecuencia"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                <label>¿FUMA?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="fuma" 
                                        value="si"
                                        
                                        {...register('fuma', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, fuma: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="fuma" 
                                        value="no"
                                        {...register('fuma', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, fuma: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="fuma"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    historiaClinica.fuma === "si"
                                    ? <div>
                                        <label>¿DESDE CUANDO FUMA? (Fecha aproximada)</label>
                                        <input 
                                            type="text" 
                                            name="desdecuandofuma" 
                                            value={historiaClinica.desdecuandofuma}
                                            {...register('desdecuandofuma'/*, { required: "Este campo es obligatorio" }*/)}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, desdecuandofuma: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="desdecuandofuma"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                {
                                    historiaClinica.fuma === "si"
                                    ? <div>
                                        <label>¿QUÉ FUMA?</label>
                                        <br/>

                                        <input 
                                            className="checkbox"
                                            type="checkbox" 
                                            name="quefuma" 
                                            value="tabaco"
                                            {...register("quefuma", { required: "Este campo es obligatorio" })}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label>Tabaco</label>
                                        <br/>

                                        <input 
                                            className="checkbox"
                                            type="checkbox" 
                                            name="quefuma" 
                                            value="marihuana"
                                            {...register("quefuma", { required: "Este campo es obligatorio" })}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label>Marihuana</label>
                                        <br/>

                                        <input 
                                            className="checkbox"
                                            type="checkbox" 
                                            name="quefuma" 
                                            value="vaper"
                                            {...register("quefuma", { required: "Este campo es obligatorio" })}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label>Vaper</label>
                                        <ErrorMessage
                                            errors={errors}
                                            name="quefuma"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }

                                    

                                {
                                    historiaClinica.fuma === "si"
                                    ? <div>
                                        <label>¿CUÁNTOS CIGARRILLOS POR DÍA?</label>
                                        <input 
                                            type="text" 
                                            name="fumacuantos" 
                                            value={historiaClinica.fumacuantos}
                                            {...register('fumacuantos'/*, { required: "Este campo es obligatorio" }*/)}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, fumacuantos: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="fumacuantos"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }   

                                {
                                    historiaClinica.fuma === "no"
                                    ? <div>
                                        <label>¿ALGUNA VEZ FUMÓ?</label>
                                        <div className="radiobutton">
                                            <label>SI</label>
                                            <input 
                                                type="radio" 
                                                name="fumo" 
                                                value="si"
                                                
                                                {...register('fumo', { required: "Este campo es obligatorio" })}
                                                onChange={(e) => setHistoriaClinica({...historiaClinica, fumo: e.target.value})}
                                            />
                                            <label>NO</label>
                                            <input 
                                                type="radio" 
                                                name="fumo" 
                                                value="no"
                                                {...register('fumo', { required: "Este campo es obligatorio" })}
                                                onChange={(e) => setHistoriaClinica({...historiaClinica, fumo: e.target.value})}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="fumo"
                                                render={({ message }) => <p>{message}</p>}
                                            />
                                        </div>
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }

                                {
                                    historiaClinica.fumo === "si"
                                    ? <div>
                                        <label>¿CUÁNTOS CIGARRILLOS POR DÍA?</label>
                                        <input 
                                            type="text" 
                                            name="fumocuantos" 
                                            value={historiaClinica.fumocuantos}
                                            {...register('fumocuantos'/*, { required: "Este campo es obligatorio" }*/)}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, fumocuantos: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="fumocuantos"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }        

                                {
                                    historiaClinica.fumo === "si"
                                    ? <div>
                                        <label>¿CUÁNDO DEJÓ DE FUMAR? (Fecha aproximada)</label>
                                        <input 
                                            type="text" 
                                            name="cuandodejofumar" 
                                            value={historiaClinica.cuandodejofumar}
                                            {...register('cuandodejofumar'/*, { required: "Este campo es obligatorio" }*/)}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, cuandodejofumar: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cuandodejofumar"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }                           

                                <label>¿CONSUME ALCOHOL?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="alcohol" 
                                        value="si"
                                        {...register('alcohol', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, alcohol: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="alcohol" 
                                        value="no"
                                        {...register('alcohol', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, alcohol: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="alcohol"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                                {
                                    // historiaClinica.alcohol === "si"
                                    // ? <div>
                                    //     <label>¿CON QUÉ FRECUENCIA?</label>
                                    //     <input
                                    //         type="text" 
                                    //         name="alcoholcuanto" 
                                    //         value={historiaClinica.alcoholcuanto}
                                    //         {...register('alcoholcuanto'/*, { required: "Este campo es obligatorio" }*/)}
                                    //         onChange={(e) => setHistoriaClinica({...historiaClinica, alcoholcuanto: e.target.value})}
                                    //     />
                                    //     <ErrorMessage
                                    //         errors={errors}
                                    //         name="alcoholcuanto"
                                    //         render={({ message }) => <p>{message}</p>}
                                    //     />
                                    // </div>
                                    // : <div style={{display:"none"}}></div>  
                                } 

                                {
                                    historiaClinica.alcohol === "si"
                                    ? <div className="radiobutton">
                                        <div>
                                        <input 
                                            type="radio" 
                                            name="alcohol" 
                                            value="consumodiario"
                                            {...register('alcoholcuando', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, alcoholcuando: e.target.value})}
                                        />
                                        <label>CONSUMO DIARIO</label>
                                        <br/>
                                        
                                        <input 
                                            type="radio" 
                                            name="alcoholcuando" 
                                            value="consumosocial"
                                            {...register('alcoholcuando', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, alcoholcuando: e.target.value})}
                                        />
                                        <label>CONSUMO SOCIAL</label>
                                        <br/>

                                        <input 
                                            type="radio" 
                                            name="alcoholcuando" 
                                            value="finesdesemana"
                                            {...register('alcoholcuando', { required: "Este campo es obligatorio" })}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, alcoholcuando: e.target.value})}
                                        />
                                        <label>FINES DE SEMANA</label>
                                        </div>
                                        <ErrorMessage
                                            errors={errors}
                                            name="alcoholcuando"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                } 

                                {
                                    historiaClinica.alcohol === "si"
                                    ? <div>
                                        <label>OTROS</label>
                                        <input
                                            type="text" 
                                            name="alcoholcuanto" 
                                            value={historiaClinica.alcoholcuanto}
                                            {...register('alcoholcuanto'/*, { required: "Este campo es obligatorio" }*/)}
                                            onChange={(e) => setHistoriaClinica({...historiaClinica, alcoholcuanto: e.target.value})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="alcoholcuanto"
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                    : <div style={{display:"none"}}></div>  
                                }   

                                <label>¿ESTARÍA DISPUESTO A CAMBIAR SUS HÁBITOS LUEGO DE LA INTERVENCIÓN SEGÚN RECOMENDACIÓN MÉDICA?</label>
                                <div className="radiobutton">
                                    <label>SI</label>
                                    <input 
                                        type="radio" 
                                        name="cambiohabitopostintervencion" 
                                        value="si"
                                        {...register('cambiohabitopostintervencion', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, cambiohabitopostintervencion: e.target.value})}
                                    />
                                    <label>NO</label>
                                    <input 
                                        type="radio" 
                                        name="cambiohabitopostintervencion" 
                                        value="no"
                                        {...register('cambiohabitopostintervencion', { required: "Este campo es obligatorio" })}
                                        onChange={(e) => setHistoriaClinica({...historiaClinica, cambiohabitopostintervencion: e.target.value})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="cambiohabitopostintervencion"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="container-form-historia-clinica bkg-gris">
                            <h3>¿CÓMO CONOCIO AL DR FEDERICO LUQUE?</h3>
                            <div className="box-historia-clinica">
                                <textarea 
                                    type="text" 
                                    name="comoconocioluque" 
                                    rows="6" 
                                    value={historiaClinica.comoconocioluque}
                                    {...register('comoconocioluque', { required: "Este campo es obligatorio" })}
                                    onChange={(e) => setHistoriaClinica({...historiaClinica, comoconocioluque: e.target.value})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="comoconocioluque"
                                    render={({ message }) => <p>{message}</p>}
                                />

                            </div>
                        </div>

                        <div className="validar">
                            <label className="legal-form">DECLARO BAJO JURAMENTO QUE LOS DATOS CONSIGNADOS SON CORRECTOS, MANIFESTANDO EXPRESAMENTE NO HABER FALSEADO NI OMITIDO DATO ALGUNO QUE DEBA CONTENER, SIENDO FIEL EXPRESIÓN DE LA VERDAD</label>
                            <input 
                                className="checkbox"
                                type="checkbox" 
                                name="valido"
                                checked={historiaClinica.valido === "true"} 
                                {...register("valido", { required: "Confirmar este campo es obligatorio" })}
                                onChange={(e) => setHistoriaClinica({...historiaClinica, valido: e.target.checked ? "true" : "false"})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="valido"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        { !historiaClinica
                            ? ""
                            : <input type="submit" value="ENVIAR" className="btnN" onClick={()=> {}}/>
                        }
                    </form>
                </div>
        </section>
    )
}

export default HistoriaClinicaForm