import React, { useState, useRef, useEffect } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../services/firebase";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";

const ContactoForm = () => {
    const form = useRef();
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [contact, setContact] = useState({
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        mensaje: "",
    });

    const sendEmail = () => {
        emailjs.sendForm("contacto", "mail-contacto", form.current, "Ep8vE15fL2gJCK7ad")
            .then((result) => {
                console.log(result.text);
            })
            .catch((error) => {
                console.log(error.text);
            });
    };

    const createContact = () => {
        addDoc(collection(db, "contact"), { contact })
            .then(() => {
                swal("¡GRACIAS POR CONTACTARTE!", "En breve te estaremos respondiendo", "success");
                sendEmail();
                navigate("/");
            })
            .catch((error) => {
                console.log(error);
                swal("¡Ups!", "Algo falló", "error");
            });
    };

    // Sincroniza el estado con React Hook Form cuando cambia contact (ej. autocompletado)
    useEffect(() => {
        Object.keys(contact).forEach((key) => {
            setValue(key, contact[key]);
        });
    }, [contact, setValue]);

    return (
        <section>
            <div className="contacto">
                <div className="container-form">
                    <h4>¿TENÉS PREGUNTAS?</h4>
                    <h2>CONTACTANOS</h2>
                    <form ref={form} onSubmit={handleSubmit(createContact)}>
                        <input 
                            type="text" 
                            name="nombre" 
                            placeholder="NOMBRE" 
                            value={contact.nombre}
                            {...register("nombre", { required: "El campo NOMBRE es obligatorio" })}
                            onChange={(e) => setContact({ ...contact, nombre: e.target.value })}
                        />
                        <ErrorMessage errors={errors} name="nombre" render={({ message }) => <p>{message}</p>} />

                        <input 
                            type="text" 
                            name="apellido" 
                            placeholder="APELLIDO" 
                            value={contact.apellido}
                            {...register("apellido", { required: "El campo APELLIDO es obligatorio" })}
                            onChange={(e) => setContact({ ...contact, apellido: e.target.value })}
                        />
                        <ErrorMessage errors={errors} name="apellido" render={({ message }) => <p>{message}</p>} />

                        <input 
                            type="email" 
                            name="email" 
                            placeholder="EMAIL" 
                            value={contact.email}
                            {...register("email", { required: "El campo EMAIL es obligatorio" })}
                            onChange={(e) => setContact({ ...contact, email: e.target.value })}
                        />
                        <ErrorMessage errors={errors} name="email" render={({ message }) => <p>{message}</p>} />

                        <input 
                            type="number" 
                            name="telefono" 
                            placeholder="TELÉFONO" 
                            pattern="[0-9]+" 
                            value={contact.telefono}
                            {...register("telefono", { required: "El campo TELÉFONO es obligatorio" })}
                            onChange={(e) => setContact({ ...contact, telefono: e.target.value })}
                        />
                        <ErrorMessage errors={errors} name="telefono" render={({ message }) => <p>{message}</p>} />

                        <textarea 
                            name="mensaje" 
                            rows="6" 
                            placeholder="MENSAJE" 
                            value={contact.mensaje}
                            {...register("mensaje")}
                            onChange={(e) => setContact({ ...contact, mensaje: e.target.value })}
                        />

                        {contact && <input type="submit" value="ENVIAR" className="btnN" />}
                    </form>
                </div>
                <div className="container-img">
                    <img src="../../img/contacto-dr-luque.webp" alt="Dr. Federico Luque"/>
                </div>
            </div>
        </section>
    );
};

export default ContactoForm;
